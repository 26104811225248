




































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CommentStore, JWTStore, UserStore } from "@/store/modules";
import { roleChecker } from "@/utils/jwt";

@Component({
  metaInfo: {
    title: "Admin",
    meta: [{ name: "description", content: "Admin Panel", vmid: "admin" }]
  }
})
export default class Adminmenu extends Vue {
  title: string = "";
  getAdminCountInterval: any = {};
  get unseenStatus() {
    return CommentStore.UnseenStatus;
  }

  get counts() {
    return UserStore.AdminCount;
  }

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  created() {
    // CommentStore.getCommentUnseenCount();
    UserStore.getAdminCount();
    this.getAdminCountInterval = setInterval(UserStore.getAdminCount, 1800000);

    this.title = this.$t("menu").toString();
  }

  destroyed() {
    clearInterval(this.getAdminCountInterval);
  }

  get role() {
    return JWTStore.role;
  }
}
